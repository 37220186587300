<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import loadSections from "@/mixins/loadSections";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";

export default {
  name: "MenuOrderDetailsPayment",

  metaInfo: { title: "Mi Pedido" },

  extends: View,

  mixins: [
    firebaseDeliveryBindOrder,
    loadSections([
      "layout-header-menu-with-return-button-and-title",
      "public-menu-order-details-payment-delivery-methods",
      "public-menu-order-details",
      "public-menu-order-details-payment-tip-selector",
      "public-menu-weborders-delivery-payment-shipping-form",
      "public-menu-order-details-payment-available-methods",
      "layout-footer-menu-order-details-payment",
    ]),
  ],
};
</script>
